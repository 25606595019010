import { ChangeDetectorRef, Component } from '@angular/core';
import { auth$ } from '@oc/auth';

@Component({
  selector: 'oc-communications-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  appVisible = false;

  constructor(private ref: ChangeDetectorRef) {}

  ngOnInit() {
    auth$.subscribe((val: { isAuthenticated: boolean; name: string, username: string }) => {
      this.appVisible = val.isAuthenticated;      
      if (val.isAuthenticated === false) {
        this.appVisible = false;
      } else {
        console.log('showing communication settings!');
        this.appVisible = true;
      }
      console.log(this.appVisible);
      this.ref.detectChanges();
    });
  }
}
