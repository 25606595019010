export const environment = {
    production: false,
    config: {
        tenant: '36da45f1-dd2c-4d1f-af13-5abe46b99921',
        popUp: false,
        clientId: 'e296c2d9-4005-4926-91fa-0a7ed8b42635',
        endpoints: {
            'https://donecloudapi-dev.azurewebsites.net/api': 'aa028eaa-acff-4e1c-8f45-2d7ca6d18a61'
        },
        cacheLocation: 'localStorage',
    },
    apiUrl: 'https://onecloudcommunicationsettings-dev.azurewebsites.net/api',
    apiResource: 'aa028eaa-acff-4e1c-8f45-2d7ca6d18a61',
};
