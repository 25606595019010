import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SettingsService } from '../shared/settingsService';
import { auth$ } from '@oc/auth';
import { CommunicationSetting } from '../shared/model/communicationSetting';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { SubscriptionUpdateModel } from '../shared/model/subscriptionUpdateModel';

@Component({
  selector: 'oc-communications-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.less']
})
export class SettingsComponent implements OnInit {
  cloudServices = new Array<CommunicationSetting>();
  oneCloudServices = new Array<CommunicationSetting>();
  settingsSaved: boolean;
  errorDetected: boolean;
  
  constructor(private settingsService: SettingsService,
    private toastr: ToastrService,
    private ref: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.errorDetected = false;
    this.getCommunicationSettings();
  }

  getCommunicationSettings() {
    this.settingsService.getSubscriptions(auth$._value.username).subscribe(rslt => {
      this.setSettings(rslt);
    }, error => {
      console.log(error);
      this.errorDetected = true;
      this.ref.detectChanges();
    });
  }

  save() {
    var model = new SubscriptionUpdateModel();
    model.email = auth$._value.username;
    var subscriptions = this.oneCloudServices;
    subscriptions = subscriptions.concat(this.cloudServices);
    model.subscriptions = subscriptions;
    this.settingsService.saveSettings(model).subscribe(rslt => {
      this.settingsSaved = true;
      this.setSettings(rslt);      
    }, error => {
      console.log(error);
      this.errorDetected = true;
      this.ref.detectChanges();
    });
  }

  setSettings(settings: CommunicationSetting[]) {
    this.cloudServices = _.filter(settings, function (communicationSetting: CommunicationSetting) {
      return communicationSetting.subscriptionGroup === 'CloudService';
    });
    this.oneCloudServices = _.filter(settings, function (communicationSetting: CommunicationSetting) {
      return communicationSetting.subscriptionGroup === 'OneCloud';
    });
    this.ref.detectChanges();
  }

}
