<section class="main-body">
    <div class="title-div">
        <h1>
            OneCloud Subscription Settings
        </h1>
    </div>

    <div class="title-desc">
        <p class="p-2 text-primary" *ngIf="settingsSaved === true">Your request has been completed.</p>
        <p class="error" *ngIf="errorDetected === true">An unexpected error occurred while retrieving your subscription list.  Please try again. </p>
        <h5>
            OneCloud wants to keep you informed on topics related to the Deloitte Cloud Services platform, OneCloud
            features/capabilities, and cloud training. Select a category below to subscribe or unsubscribe to email
            communications related to those topics.
        </h5>
        <p><b>Note:</b> Technical Administrators for cloud accounts will not be able to unsubscribe from platform news
            where they are a Technical Administrator.</p>
    </div>


    <div class="row">
        <div class="col-lg-12">
            <div class="row form-group">
                <div *ngIf="cloudServices.length > 0">
                    <h5>Cloud Service Provider & Platform Subscriptions</h5>
                    <p>New and improved features/capabilities, security and compliance changes, outages, etc.</p>

                    <div class="checkbox col-lg-6" style="height: auto">
                        <label>
                            <div class="form-group" *ngFor="let cloudService of cloudServices; let i = index">
                                <label class="radio">
                                    <input type="checkbox" class="radio-input" name="cloudService"
                                        [value]="cloudService.commSubscriptionTypeId" [(ngModel)]="cloudService.isOpted"
                                        [title]="cloudService.description" [disabled]="cloudService.isDisabled" />
                                    {{cloudService.commSubscriptionType}}
                                </label>
                            </div>

                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>

                <div *ngIf="oneCloudServices.length > 0">
                    <div class="col-lg-12 border"></div>
                    <div class="col-lg-12 non-cloud-des">
                        <h5>OneCloud Subscriptions</h5>
                        <p>Newsletter, Ask me Anything, training, vendor briefs</p>
                    </div>
                    <div class="checkbox col-lg-6" style="height: auto">
                        <label>
                            <div class="form-group" *ngFor="let cloudService of oneCloudServices; let i = index">
                                <label class="radio">
                                    <input type="checkbox" class="example-margin radio-input" name="oneCloudService"
                                        [value]="cloudService.commSubscriptionTypeId" [(ngModel)]="cloudService.isOpted"
                                        [title]="cloudService.description" />
                                    {{cloudService.commSubscriptionType}}
                                </label>
                            </div>
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="" *ngIf="errorDetected === false">
                <input type="button" class="btn btn-info" name="submit" (click)="save()"  value="Save" />
            </div>
        </div>
    </div>

</section>