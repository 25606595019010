import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DataService {

    toHttpParams(obj: any): HttpParams {
        let params = new HttpParams();
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const val = obj[key];
                if (val !== null && val !== undefined) {
                    params = params.append(key, val.toString());
                }
            }
        }
        return params;
    }

    constructor(private http: HttpClient) { }

    getNoParams<T>(url: string) {
        return this.http.get<T>(url);
    }

    getNoParamsWithoutLoadingProgressBar<T>(url: string) {
        const headers = new HttpHeaders({ ignoreProgressBar: '' });
        return this.http.get<T>(url, { headers });
    }

    getTyped<T, U>(param: T, url: string) {
        return this.http.get<U>(url, { params: this.toHttpParams(param) });
    }

    post<T>(data: T, url: string) {
        return this.http.post(url, data);
    }

    postWithType<T, U>(data: T, url: string): Observable<U> {
        return this.http.post<U>(url, data);
      }

    postNoParams<T>(url: string): Observable<T> {
        return this.http.post<T>(url, null);
    }

    postParams<T>(param: T, url: string) {
        return this.http.post(url, {}, { params: this.toHttpParams(param) });
    }
}
