import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiUrlService } from "./api-url.service";
import { DataService } from "./data.service";
import { CommunicationSetting } from "./model/communicationSetting";
import { SubscriptionUpdateModel } from "./model/subscriptionUpdateModel";


@Injectable({
    providedIn: 'root'
})

export class SettingsService {
    constructor(private dataService: DataService, private apiUrlService: ApiUrlService) {
    }

    getSubscriptions(email: string): Observable<CommunicationSetting[]> {
        return this.dataService.getNoParams<CommunicationSetting[]>(this.apiUrlService.getSubscriptions(email));
    }

    saveSettings(data: SubscriptionUpdateModel): Observable<CommunicationSetting[]> {
        return this.dataService.postWithType<SubscriptionUpdateModel, CommunicationSetting[]>(data, this.apiUrlService.saveSettings);
    }


}
