<div class="wrapper" *ngIf="appVisible === true">
  <mat-sidenav-container class="notifications-container mat-sidenav-container">
    <mat-sidenav-content>
      <div class="root-container-onecloudapp">
        <div class="container-fluid">
          <div class="body-section">
            <div class="row">
              <div class="col-sm-12">
                <router-outlet></router-outlet>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>