import api from "!../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[7].rules[0].oneOf[1].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[7].rules[0].oneOf[1].use[2]!../node_modules/less-loader/dist/cjs.js??ruleSet[1].rules[7].rules[1].use[0]!./styles.less";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};